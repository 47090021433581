import Vue from 'vue'
import _ from 'lodash'
import moment from 'moment'
import * as XLSX from 'xlsx'

import storages from '../apis/local-storages'
import Message from '@/constants/message'
import {actionEnum} from '@/constants/constants'
import {fileTypeEnum} from '@/constants/import-file'

const authStorage = storages.authLocalStorage()
export const convertDate = date => {
  return moment(date).format('DD/MM/YYYY HH:mm')
}

export const convertDatewithTimezone = date => {
  return moment(date).format('DD/MM/YYYY HH:mm:ss Z')
}

export const convertDateBirthDate = date => {
  return moment(date).format('DD/MM/YYYY')
}

export const convertDateFullMonthDate = (date, locale = '') => {
  return moment(date)
    .locale(locale)
    .format('DD MMMM YYYY')
}

export const convertSelectedDate = date => {
  return moment(date).format('DD-MM-YYYY')
}

export const convertDatePicker = date => {
  return moment(date).format('YYYY-MM-DD')
}

export const convertStrToDate = date => {
  return moment(date).toDate()
}

export const converMathCeil = number => {
  return Math.ceil(number)
}

export const isUrl = url => {
  if (url.includes(' ')) return false
  try {
    new URL(url)
  } catch (_) {
    return false
  }
  return true
}

export const isEnglishAlphabet = str => {
  return /^[A-Za-z0-9]+$/.test(str)
}
export const isThaiAlphabet = str => {
  return /^[\u0E00-\u0E7F]+$/.test(str)
}
export const isAliasAllow = str => {
  // hyphen underscore and whitespace
  return /^[-_\s]+$/.test(str)
}
export const isLowercase = str => {
  return /^[a-z0-9]+$/.test(str)
}

export const isValidPasswordChar = str => {
  // eslint-disable-next-line no-useless-escape
  return /^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9-]+$/.test(str)
}

export const convertCommaNumberToInteger = n => {
  if (!n || n === '') {
    return 0
  }

  return parseInt(n.toString().replace(/\D/g, ''))
}

export const convertIntegerToCommaNumber = n => {
  if (!n) {
    return '0'
  }
  const parts = n.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
  // return parseInt(n.toString().replace(/\D/g, ''))
  //   .toString()
  //   .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const convertToRolePermissionByOrg = roles => {
  let orgs = {}

  for (let r of roles) {
    let key = r.org_role.org
    let value =
      key in orgs
        ? orgs[key]
        : {
            roles: [],
            permissions: []
          }

    if (!(r.org_role.codename in value.roles)) {
      value.roles.push(r.org_role.codename)
    }

    for (let p of r.org_role.permissions) {
      if (!(p in value.permissions)) {
        value.permissions.push(p.codename)
      }
    }
    orgs = {...orgs, ...{[key]: value}}
  }
  return orgs
}

export const isStaff = () => {
  return authStorage.getUser().is_staff
}

export const hasPermision = permCode => {
  // TODO: SUPPORT PERMISSION FOR EACH ORGANIZATION
  try {
    let permissions = Object.values(authStorage.getRoles()).reduce(
      (acc, curr) => {
        const permissions = curr.permissions.map(permission =>
          permission.toLowerCase()
        )
        return [...acc, ...permissions]
      },
      []
    )
    return permissions.includes(permCode.toLowerCase())
  } catch {
    return false
  }
}

export const hasPermissions = permCodes => {
  // TODO: SUPPORT PERMISSION FOR EACH ORGANIZATION
  // * (note) to allow multiple array
  try {
    let permissions = Object.values(authStorage.getRoles()).reduce(
      (acc, curr) => {
        const permissions = curr.permissions.map(permission =>
          permission.toLowerCase()
        )
        return [...acc, ...permissions]
      },
      []
    )
    return permCodes.some(perm => permissions.includes(perm.toLowerCase()))
  } catch {
    return false
  }
}

export const hasPermissionsByOrgs = (permCode, org_codenames = []) => {
  const roles = authStorage.getRoles()
  const permissions = Object.keys(roles)
  const orgs = []
  permissions.forEach(org => {
    if (roles[org].permissions.includes(permCode)) {
      orgs.push(org)
    }
  })

  return orgs.some(perm => org_codenames.includes(perm))
}

export const searchHandler = (timer, clearTimeout, cb) => {
  if (timer) {
    clearTimeout(timer)
    timer = null
  }
  return (timer = setTimeout(() => {
    cb()
  }, 500))
}

export const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export const convertExcelFileToArray = file =>
  new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = () => {
      const wb = XLSX.read(reader.result, {type: 'binary'})
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_json(ws)
      resolve(data)
    }
    reader.onerror = error => reject(error)
  })

export const convertCsvFileToArray = file =>
  new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => {
      const wb = XLSX.read(reader.result, {type: 'binary'})
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_json(ws)
      resolve(data)
    }
    reader.onerror = error => reject(error)
  })

export const convertSelectedData = (data, orgs, selectOrg) => {
  selectOrg = selectOrg ? selectOrg : []
  orgs = orgs.map(org => {
    return {...org}
  })
  selectOrg = selectOrg.map(org => {
    return {...org}
  })
  orgs = orgs.reduce((a, c) => a.add(c.codename), new Set())
  selectOrg = selectOrg.filter(v => !orgs.has(v.codename))
  let results = data.map(object => {
    let data = {}
    data.codename = object
    data.selected = true
    return data
  })
  results = [...results, ...selectOrg]
  let keys = ['codename']
  let filtered = results.filter(
    (s => o => (k => !s.has(k) && s.add(k))(keys.map(k => o[k]).join('|')))(
      new Set()
    )
  )

  return filtered
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const generateAddress = (
  line1,
  line2,
  district,
  province,
  country,
  zipcode
) => {
  const isNotNull = value => value !== null
  const details = [line1, line2, district, province, country, zipcode]
  if (details.every(isNotNull)) {
    return `${line1} ${line2}, ${district}, ${province}, ${country} ${zipcode}`
  }
  if (details.some(isNotNull)) {
    return details.filter(isNotNull).join(', ')
  }
  return '-'
}

export const isNewerVersion = (oldVer, newVer) => {
  const oldParts = oldVer.split('.')
  const newParts = newVer.split('.')
  for (let i = 0; i < newParts.length; i++) {
    const a = parseInt(newParts[i])
    const b = parseInt(oldParts[i])
    if (a > b) return true
    if (a < b) return false
  }
  return false
}

export const increasePatchVersion = version => {
  const [major, minor, patch] = version.split('.', 3)
  return [major, minor, parseInt(patch) + 1].join('.')
}

export const snackbarSuccessContent = (pageName, snackTitle) => {
  if (snackTitle === actionEnum.STATUS_UPDATED) {
    return Message.SnackBar.Successfully(`${pageName} status`, 'updated', false)
  } else if (snackTitle === actionEnum.ORDER_UPDATED) {
    return 'Fixed the display of order numbers successfully.'
  } else {
    return Message.SnackBar.Successfully(pageName, snackTitle)
  }
}

export const snackbarFailContent = (pageName, snackTitle) => {
  if (snackTitle === actionEnum.STATUS_UPDATED) {
    return Message.SnackBar.Failed(`${pageName} status`, 'updated', false)
  } else {
    return Message.SnackBar.Failed(pageName, snackTitle)
  }
}

export const isCsv = file => file.type.includes(fileTypeEnum.CSV)
export const isXlsx = file => file.type.includes(fileTypeEnum.XLSX)
export const isXls = file => file.type.includes(fileTypeEnum.XLS)
export const isPng = file => file.type.includes(fileTypeEnum.PNG)
export const isJpg = file => file.type.includes(fileTypeEnum.JPG)
export const isSvg = file => file.type.includes(fileTypeEnum.SVG)

export const validateObject = (object, schema) =>
  Object.keys(schema)
    .filter(key => !schema[key](object[key]))
    .map(key => `${key} is invalid.`)

export const isSameBranch = (row, selectedBranch) =>
  _.has(row, 'Branch') && row.Branch === selectedBranch

export const validateRows = (rows, rules, selectedBranch) => {
  const errors = []
  rows.every(d => {
    let errorRows = [...validateObject(d, rules)]
    if (!isSameBranch(d, selectedBranch))
      errorRows = [...errorRows, 'Branch is not same as selected']
    if (errorRows.length > 0) {
      errors.push(errorRows)
      return false
    }
    return true
  })
  return errors.length <= 0
}

export const validateFile = async (file, rules, selectedBranch) => {
  let isValid = true

  if (isCsv(file)) {
    await convertCsvFileToArray(file)
      .then(data => {
        isValid = validateRows(data, rules, selectedBranch)
      })
      .catch(e => {
        Vue.$log.error(e)
      })
    return isValid
  } else if (isXlsx(file) || isXls(file)) {
    await convertExcelFileToArray(file)
      .then(data => {
        isValid = validateRows(data, rules, selectedBranch)
      })
      .catch(e => {
        Vue.$log.error(e)
      })
    return isValid
  } else {
    return isValid
  }
}

export const splitToDateAndTime = datetime => {
  const dateObj = new Date(datetime)
  const momentObj = moment(dateObj)
  const dateString = momentObj.format('YYYY-MM-DD')
  const timeString = momentObj.format('HH:mm')
  return {dateString, timeString}
}

export const swapArrayElements = (arr, fromIndex, toIndex) => {
  if (
    fromIndex < 0 ||
    fromIndex >= arr.length ||
    toIndex < 0 ||
    toIndex >= arr.length ||
    fromIndex === toIndex
  ) {
    // Invalid index values or no change needed
    return arr
  }

  const [removedElement] = arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, removedElement)

  return arr
}

export const calculateDiscountPrice = (discount, discountType, fullPrice) => {
  // * (note): if false then its percentage
  const discountNum = discount === '' ? 0 : discount
  if (discountType === 'PERCENTAGE') {
    const discountpercentage = (100 - discountNum) / 100
    const price =
      Math.round((fullPrice * discountpercentage + Number.EPSILON) * 100) / 100
    const formatedPrice = convertIntegerToCommaNumber(
      Math.round(parseFloat(price))
    )
    return price >= 0 ? (price === 0 ? '0' : formatedPrice) : `0`
  } else {
    const price = fullPrice - discountNum
    const formatedPrice = convertIntegerToCommaNumber(price)
    return price >= 0 ? (price === 0 ? '0' : formatedPrice) : `0`
  }
}
