import Vue from 'vue'
import {convertFileToBase64} from '../helper/helper'
import {metaEnum} from '@/constants/package'

// Initial state.
const defaultState = () => {
  return {
    metaObject: {
      SEO: [
        {
          code: 'th',
          title: '',
          description: '',
          image: ''
        },
        {
          code: 'en',
          title: '',
          description: '',
          image: ''
        }
      ],
      FACEBOOK: [
        {
          code: 'th',
          title: '',
          description: '',
          image: ''
        },
        {
          code: 'en',
          title: '',
          description: '',
          image: ''
        }
      ],
      X: [
        {
          code: 'th',
          title: '',
          description: '',
          image: ''
        },
        {
          code: 'en',
          title: '',
          description: '',
          image: ''
        }
      ]
    },
    metaImageErrorMessage: {
      [metaEnum.SEO]: {
        en: '',
        th: ''
      },
      [metaEnum.FACEBOOK]: {
        en: '',
        th: ''
      },
      [metaEnum.X]: {
        en: '',
        th: ''
      }
    },
    isMetaImageError: {
      [metaEnum.SEO]: {
        en: false,
        th: false
      },
      [metaEnum.FACEBOOK]: {
        en: false,
        th: false
      },
      [metaEnum.X]: {
        en: false,
        th: false
      }
    },
    duplicateContent: {
      [metaEnum.SEO]: false,
      [metaEnum.FACEBOOK]: false,
      [metaEnum.X]: false
    },
  }
}

// State
let state = defaultState()

// Actions
const actions = {
  setMetaObject({commit}, data) {
    commit('SET_META_OBJECT', data)
  },
  setMetaImageError({commit}, data) {
    commit('SET_META_IMAGE_ERROR', data)
  },
  setMetaImage({commit}, data) {
    commit('SET_META_IMAGE', data)
  },
  resetState({commit}) {
    commit('RESET_STATE')
  },
}

// Mutations
const mutations = {
  SET_META_OBJECT(state, data) {
    state.metaObject = data
  },
  SET_META_IMAGE_ERROR(state, {lang, type, isError, message}) {
    state.isMetaImageError[type][lang] = isError
    state.metaImageErrorMessage[type][lang] = message
  },
  SET_META_IMAGE(state, {lang, type, file}) {
    convertFileToBase64(file)
      .then(base64 => {
        if (lang === 'th') {
          state.metaObject[type][0].image = base64
        }
        if (lang === 'en') {
          state.metaObject[type][1].image = base64
        }
      })
      .catch(e => {
        Vue.$log.error(e)
      })
  },
  RESET_STATE(state) {
    state.metaObject = defaultState().metaObject
    state.isMetaImageError = defaultState().isMetaImageError
    state.metaImageErrorMessage = defaultState().metaImageErrorMessage
    state.duplicateContent = defaultState().duplicateContent
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
